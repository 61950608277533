import HomeIcon from "@mui/icons-material/Home";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import HistoryIcon from "@mui/icons-material/History";
import HubIcon from "@mui/icons-material/Hub";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PrintIcon from "@mui/icons-material/Print";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import UploadIcon from "@mui/icons-material/Upload";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { IconType } from "../Types/IconType";

export type SubmenuType = {
  name: string;
  map?: { [key: string]: string };
  icon?: IconType;
  breadcrumbIcon?: { [key: string]: IconType };
};

export const SideBarItems: SubmenuType[] = [
  { name: "Home", icon: HomeIcon },
  { name: "Add Party", icon: PersonAddAltIcon },
  { name: "Diamond History", icon: HistoryIcon },
  { name: "Rapnet", icon: HubIcon },
  { name: "Party Dues", icon: PendingActionsIcon },
  {
    name: "Packet Management",
    map: {
      "packet-printing": "Packet Printing",
      "view-pending-packets": "View Pending Packets",
      "repair-upload": "Repair Upload",
    },
    breadcrumbIcon: {
      "packet-printing": PrintIcon,
      "view-pending-packets": WorkHistoryIcon,
      "repair-upload": UploadIcon,
    },
  },
  {
    name: "Account Management",
    map: {
      "account-entries": "Account Entries",
      "balance-sheet": "Balance Sheet",
    },
    breadcrumbIcon: {
      "account-entries": RecentActorsIcon,
      "balance-sheet": AccountBalanceIcon,
    },
  },
];
