import React, { useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Box,
    IconButton,
    Collapse,
    Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { tableSpec } from "../Constants/FilterFormItems";


interface Partner {
    partner_name: string,
    partner_percentage: number
}
interface StockData {
    last_stock_id: string;
    shape?: string;
    size_start: number;
    size_end: number;
    color?: string;
    intensity: string;
    overtone: string;
    fancy_color: string;
    clarity?: string;
    cut_type: string;
    cut_from: string;
    cut_to: string;
    polish_from: string;
    polish_to: string;
    symmetry_from: string;
    symmetry_to: string;
    fluorescence?: string;
    color_fluorescence: string;
    location: string; // default: "India"
    weight: number;
    kapan_number: string;
    lab_reports?: string;
    length: number;
    width: number;
    height: number;
    diameter: number;
    depth: number;
    table_size: number;
    girdle: number;
    age: number;
    cr_angle: number;
    cr_height: number;
    p_angle: number;
    p_depth: number;
    comment: string;
    // partners: Partner[];
    invoice_number: string;
    // deal_date: Date;
    deal_date: string;

    brokerage: number;
    broker: string;
    party: string;
    party_name: string;
    certificate_number: string;
    cur_rap: number;
    price: number;
    invoice_cmnt: string;
    purchase_back: number;
    purchase_rate: number;
}


// const stockData: StockData = {
//     // deal_date: new Date("2024-09-01"),
//     deal_date: "2024-09-01",
//     last_stock_id: "ABC12345",
//     shape: "Round",
//     size_start: 1.5,
//     size_end: 2.0,
//     color: "D",
//     clarity: "VS1",
//     price: 10000,
//     location: "Mumbai",
//     weight: 0.5,
//     intensity: "Vivid",
//     overtone: "Blue",
//     fancy_color: "Yellow",
//     cut_type: "Brilliant",
//     cut_from: "2.0",
//     cut_to: "2.5",
//     polish_from: "Excellent",
//     polish_to: "Very Good",
//     symmetry_from: "Good",
//     symmetry_to: "Very Good",
//     fluorescence: "None",
//     color_fluorescence: "Medium Blue",
//     kapan_number: "KPN987654",
//     lab_reports: "GIA123456789",
//     length: 5.5,
//     width: 5.5,
//     height: 3.5,
//     diameter: 6.0,
//     depth: 60,
//     table_size: 58,
//     girdle: 20,
//     age: 1,
//     cr_angle: 35,
//     cr_height: 15,
//     p_angle: 40.5,
//     p_depth: 43,
//     comment: "No visible inclusions No visible inclusions No visible inclusions No visible inclusions",
//     // partners: [
//     //     {
//     //         partner_name: "John Doe",
//     //         partner_percentage: 50,

//     //     },
//     //     {
//     //         partner_name: "Jane Smith",
//     //         partner_percentage: 50,
//     //     },
//     // ],

//     invoice_number: "INV20240901",
//     party_name: "ABC Diamonds",
//     brokerage: 1.5,
//     broker: "XYZ Brokers",

//     party: "XYZ Ltd.",
//     certificate_number: "CERT123456",
//     cur_rap: 12000,
//     invoice_cmnt: "Special discount applied",
//     purchase_back: 10,
//     purchase_rate: 84.1,
// };

interface StockSize {
    [key: string]: number;
}

const stockSize: StockSize = {
    deal_date: 1.5,
    last_stock_id: 2,
    shape: 1,
    size_start: 1.5,
    size_end: 1.5,
    color: 1,
    clarity: 1,
    price: 1,
    location: 1.5,
    weight: 1,
    intensity: 1,
    overtone: 1,
    fancy_color: 1,
    cut_type: 1,
    cut_from: 1,
    cut_to: 1,
    polish_from: 1,
    polish_to: 1,
    symmetry_from: 1,
    symmetry_to: 1,
    fluorescence: 1,
    color_fluorescence: 1,
    kapan_number: 1,
    lab_reports: 1,
    length: 1,
    width: 1,
    height: 1,
    diameter: 1,
    depth: 1,
    table_size: 1,
    girdle: 1,
    age: 1,
    cr_angle: 1,
    cr_height: 1,
    p_angle: 1,
    p_depth: 1,
    comment: 12,
    partner_name: 1,
    partner_percentage: 1,
    invoice_number: 1,
    party_name: 1,
    brokerage: 1,
    broker: 1,
    party: 1,
    certificate_number: 1,
    cur_rap: 1,
    invoice_cmnt: 1,
    purchase_back: 1,
    purchase_rate: 1,
};
const capitalizeFirstLetter = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
};
const StockDataCard = ({ stockData }: { stockData: StockData }) => {
    console.log("stockdata", stockData)
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const formatDate = (dateInput: string | number | Date) => {
        const date = new Date(dateInput);


        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();


        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');


        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };
    const dealDate = formatDate(stockData.deal_date);




    return (
        <Card sx={{ width: "100%", boxShadow: 3, borderRadius: 2, mb: 2 }}>

            <CardContent sx={{ margin: 0 }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1,
                    }}
                >
                    <CalendarTodayIcon sx={{ fontSize: 17, mr: 1 }} />
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        fontSize={15}
                    >
                        {dealDate}

                    </Typography>
                </Box>
                <Grid container sx={{ justifyContent: "space-between" }}>
                    {tableSpec
                        .slice(1, 9)
                        .map((key, index) => {
                            const displayValue =
                                key === "size_start" || key === "size_end"
                                    ? `${stockData["size_start"] || "-"} - ${stockData["size_end"] || "-"}`
                                    : stockData[key as keyof StockData];

                            if (key === "size_end") return null;

                            const displayKey =
                                key === "size_start" || key === "size_end"
                                    ? "size"
                                    : key.replace(/_/g, " ");

                            return (
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    lg={stockSize[key]}
                                    key={index}
                                    textAlign="center"
                                >
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        {displayValue || "-"}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {capitalizeFirstLetter(displayKey)}
                                    </Typography>

                                </Grid>
                            );
                        })}
                    <Divider orientation="vertical" flexItem />
                    <Grid
                        sx={{ order: 1, justifyContent: "flex-end" }}
                        item
                        xs={0.5}
                        lg={0.5}
                        xl={0.5}
                        sm={0.5}
                    >
                        <IconButton
                            sx={{ justifyContent: "right" }}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon
                                sx={{
                                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                                    transition: "transform 0.3s",
                                }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Grid >
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={3}
                            sx={{ mt: 2 }}
                            justifyContent={'center'}

                        >
                            {Object.keys(stockData)
                                .filter(key => key !== '__v' && key !== 'id' && key !== 'deal_date' && key !== 'in_stock')
                                .slice(9)
                                .map((key, index) => (


                                    <Grid
                                        item
                                        xs={5.3}
                                        key={key}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            borderBottom: "1px solid grey",
                                            paddingBottom: 1,
                                            marginRight: index % 2 === 0 ? 1.5 : 0,
                                            marginLeft: index % 2 !== 0 ? 1.5 : 0,
                                        }}
                                    >
                                        {/* <Typography variant="h6">{key.replace(/_/g, " ")}</Typography> */}
                                        <Typography variant="subtitle1">{capitalizeFirstLetter(key.replace(/_/g, " "))}</Typography>

                                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
                                            {stockData[key as keyof StockData] || "-"}
                                        </Typography>
                                    </Grid>



                                ))}
                        </Grid>

                    </Grid>
                </Collapse>
            </CardContent>
        </Card>
    );
};

export default StockDataCard;
