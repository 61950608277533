import React from "react";
import { Outlet } from "react-router";
import { Container } from "@mui/material";
import Footer from "../Component/Footer";
import SideNav from "../Container/SideNav";
import { CheckPermission } from "../Component/CheckPermission";
const Root: React.FC = () => {
    return (
        <div>
            <CheckPermission>
                <SideNav>
                    <Container>
                        <Outlet />
                    </Container>
                </SideNav>
                <Footer />
            </CheckPermission>

        </div>
    );
};

export default Root;
