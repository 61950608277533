import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserResponseType } from "../../Modules/Auth/api/types/UserResponseType";

export type UserState = {
  isLoading: boolean;
  error: string;
  data: UserResponseType | null;
};

const initialState: UserState = {
  isLoading: false,
  error: "",
  data: null,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<Partial<UserResponseType>>) => {
      state.data = { ...state.data, ...action.payload };
      state.isLoading = false;
    },
    setUserLoading: (state) => {
      state.isLoading = true;
    },
    setUserError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    DeleteUserData: (state) => {
      state.data = null;
      state.isLoading = false;
      state.error = ""
    },
  },
});

export const { setUserData, setUserLoading, setUserError, DeleteUserData } = userSlice.actions;

export default userSlice;
