import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Navigate, useNavigate } from "react-router";
import { ReactNode } from "react";

export const CheckPermission = ({ children }: { children: ReactNode }) => {
    const user = useSelector((state: RootState) => state.user.data);
    const navigate = useNavigate()

    if (!user) {
        return <Navigate to="/" replace />
    }

    return <>{children}</>;
};