import React from "react";

const NonCertifiedDiamond: React.FC = () => {
    return (
        <div>
            <h1>NonCertifiedDiamond</h1>
        </div>
    );
};

export default NonCertifiedDiamond;
