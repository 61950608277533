import React from "react";

const Report: React.FC = () => {
    return (
        <div>
            <h1>Report</h1>
        </div>
    );
};

export default Report;
