import React from "react";
import FilterForm from "../../../Component/FilterForm";

import { Box, Grid } from "@mui/material";


import PurchaseInvoice from "../Components/PurchaseInvoice";
import LabInformation from "../Components/LabInformation";
import PurchaseDetails from "../Components/PurchaseDetails";
import { PurchaseContextProvider } from "../Context/purchaseProviderContext";



const Purchase: React.FC = () => {


    return (
        <PurchaseContextProvider>


            <Box sx={{ flexGrow: 1, display: "flex" }}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <FilterForm />
                    </Grid>
                    <Grid item xs={12}>
                        <LabInformation />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <PartnerDetails />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <PurchaseInvoice />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PurchaseDetails />
                    </Grid>

                </Grid>
            </Box>
        </PurchaseContextProvider>

    );
};

export default Purchase;
