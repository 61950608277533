import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Title from '../../../Component/Title';
import { Color } from '../../../Theme/Color';


function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}

interface SearchData {
    title: string;
    date: string;
    shapes: string[];
    caratRange: string;
    color: string;
    clarity: string;
    cut: string;
    polish: string;
    symmetry: string;
}

interface SavedSearchProps {
    data: SearchData[];
}

const keyStyle = { color: 'gray', fontWeight: 'bold' };
const valueStyle = { color: 'black' };

export default function SavedSearch({ data }: SavedSearchProps) {
    const navigate = useNavigate();



    return (
        <React.Fragment >

            <Title >Saved Searches</Title>
            <Grid container rowSpacing={2}>
                {data.map((search, index) => (
                    <Grid item xs={12} key={index} rowSpacing={2}>
                        <Link component={RouterLink} to={`/search/${index}`} underline="none">
                            <Box border={1} padding={2} borderRadius={2} sx={{ cursor: 'pointer', '&:hover': { boxShadow: 3 } }} >
                                <Box display="flex" alignItems="center" marginBottom={1}>
                                    <IconButton size="small" disabled>
                                        <CalendarTodayIcon fontSize="small" />
                                    </IconButton>
                                    <Typography component="p" variant="body2" style={valueStyle}>
                                        {search.date}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" flexWrap="wrap" marginBottom={1}>
                                    {search.shapes.map((shape, shapeIndex) => (
                                        <Box
                                            key={shapeIndex}
                                            border={1}
                                            borderRadius={8}
                                            padding="2px 8px"
                                            margin="2px"
                                            display="inline-block"
                                        >
                                            <Typography component="p" variant="body2" style={valueStyle}>
                                                {shape}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                                <Typography component="p" variant="body2" style={keyStyle}>
                                    Carat:
                                    <Typography component="span" variant="body2" style={valueStyle}>
                                        {" "}{search.caratRange}
                                    </Typography>
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography component="p" variant="body2" style={keyStyle}>
                                            Color
                                        </Typography>
                                        <Typography component="p" variant="body2" style={valueStyle}>
                                            {search.color}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography component="p" variant="body2" style={keyStyle}>
                                            Clarity
                                        </Typography>
                                        <Typography component="p" variant="body2" style={valueStyle}>
                                            {search.clarity}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography component="p" variant="body2" style={keyStyle}>
                                            Cut
                                        </Typography>
                                        <Typography component="p" variant="body2" style={valueStyle}>
                                            {search.cut}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography component="p" variant="body2" style={keyStyle}>
                                            Polish
                                        </Typography>
                                        <Typography component="p" variant="body2" style={valueStyle}>
                                            {search.polish}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography component="p" variant="body2" style={keyStyle}>
                                            Symmetry
                                        </Typography>
                                        <Typography component="p" variant="body2" style={valueStyle}>
                                            {search.symmetry}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Link>
                    </Grid>
                ))}
            </Grid>
            <div style={{ marginTop: 16, marginBottom: 30 }}>
                <Link color="primary" href="#" onClick={(e) => { preventDefault(e); navigate('/view-all'); }}>
                    View all
                </Link>
            </div>

        </React.Fragment >
    );
}
