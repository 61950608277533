import React from 'react';
import { Box, Typography, Link, IconButton, useMediaQuery, useTheme } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Color } from '../Theme/Color';

const Footer: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{ backgroundColor: Color.DARK_BLUE, position: "static", bottom: "0vh", left: "0vh", width: "100%" }}

            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={2}
        >
            <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="center"
                gap={2}
                mb={1}
            >
                <Typography variant="body1" sx={{ color: 'white' }}>
                    Contact us:
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => window.location.href = `mailto:superb.diamonds@gmail.com`}
                >
                    superb.diamonds@gmail.com
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}
                mb={2}
            >
                <IconButton color="info" aria-label="Facebook">
                    <FacebookIcon />
                </IconButton>
                <IconButton color="info" aria-label="Twitter">
                    <TwitterIcon />
                </IconButton>
                <IconButton color="info" aria-label="Instagram" href='https://www.instagram.com/twinklediam/'>
                    <InstagramIcon />
                </IconButton>
                <IconButton color="info" aria-label="LinkedIn">
                    <LinkedInIcon />
                </IconButton>
            </Box>
            <Typography variant="body2" color="white" sx={{ textAlign: 'center' }}>
                © 2024 Superb Diamonds | Twinkle Diam. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
