import React from "react";

const AccountSettings: React.FC = () => {
  return (
    <div>
      <h1>AccountSettings</h1>
    </div>
  );
};

export default AccountSettings;
