import React from "react";

const CertifiedDiamonds: React.FC = () => {
    return (
        <div>
            <h1>CertifiedDiamonds</h1>
        </div>
    );
};

export default CertifiedDiamonds;
