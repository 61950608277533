import React from 'react'
import { Box, Card, Grid, IconButton, styled } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Shop2Icon from '@mui/icons-material/Shop2';
import ShopTwoOutlinedIcon from '@mui/icons-material/ShopTwoOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SellIcon from '@mui/icons-material/Sell';
import InventoryIcon from '@mui/icons-material/Inventory';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import Typography from '@mui/joy/Typography';
import { useNavigate } from 'react-router';
import CardContent from '@mui/joy/CardContent';
import AspectRatio from '@mui/joy/AspectRatio';
import CardOverflow from '@mui/joy/CardOverflow';
import { Color } from '../../../Theme/Color';
import { IconType } from '../../../Types/IconType';


interface DashboardCardProps {
    Icon: IconType;

    name: string;
    navigateTo: string;
    color: string
    desc: string
}

const DashboardCard = ({ Icon, name, navigateTo, color, desc }: DashboardCardProps) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(navigateTo);
    };
    return (

        <Card
            onClick={handleClick}
            data-resizable
            sx={{

                textAlign: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                height: '280px',
                '--icon-size': '90px',
                borderRadius: 4,
                '&:hover .aspectRatio': {
                    '--icon-size': '50%',
                    transition: 'font-size 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out',
                },
                '&:hover .description': {
                    opacity: 0,
                },
                '&:hover .heading': {
                    mt: 'calc(var(--icon-size)/ 1.1)',
                },
                '.aspectRatio': {
                    fontSize: 'var(--icon-size)',
                    transition: 'font-size 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out',
                },
            }}
        >
            <CardOverflow variant="solid" color="warning" sx={{ backgroundColor: color }}>
                <AspectRatio
                    className='aspectRatio'
                    variant="outlined"
                    ratio="1"
                    sx={{
                        m: 'auto',
                        transform: 'translateY(50%)',
                        borderRadius: '50%',
                        width: 'var(--icon-size)',
                        boxShadow: 'sm',
                        bgcolor: 'background.surface',
                        position: 'relative',
                    }}
                >
                    <div>
                        <Icon className='cardIcon' sx={{ fontSize: '3rem', color: color }} />
                    </div>
                </AspectRatio>
            </CardOverflow>
            <Typography className='heading' level="title-lg" sx={{ mt: 'calc(var(--icon-size)/ 1.3)', }}>
                {name}
            </Typography>
            <CardContent className='description' sx={{ maxWidth: '40ch', mx: 1, my: 3, opacity: 1 }}>
                {desc}
            </CardContent>

        </Card>
    );
}
export const DashboardCardRender = () => {
    const cardData = [
        { icon: AccountCircleIcon, name: "Account", navigateTo: "/account", desc: "Manages account entries and reports", color: '#E3B8E8' },
        { icon: DoNotDisturbAltIcon, name: "Non-Certified Diamonds", navigateTo: "/non-certified-diamonds", desc: "Manages non-certificated diamonds", color: '#FFADB3' },
        { icon: CurrencyRupeeIcon, name: "Payment", navigateTo: "/payment", desc: "Payment dues(credit/debit)", color: '#FFB4CF' },
        { icon: Shop2Icon, name: "Purchase", navigateTo: "/purchase", desc: "Store purchased diamonds", color: '#C3E9DD' },
        { icon: SummarizeIcon, name: "Report", navigateTo: "/report", desc: "View reports chronologically", color: '#98cfd7' },
        { icon: SellIcon, name: "Sell", navigateTo: "/sell", desc: "Update sold diamonds", color: '#B2C5FA' },
        { icon: InventoryIcon, name: "Stock", navigateTo: "/stock", desc: "Manage stocked diamonds", color: '#f7e8ac' },
        { icon: GppGoodIcon, name: "Certified Diamonds", navigateTo: "/certified-diamonds", desc: "Lab certified diamonds", color: '#c1aeed' },
        // Add more card data here if needed
    ];

    return (

        <Grid container spacing={5} sx={{ marginBottom: 3 }}>
            {
                cardData.map((card, index) => (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <DashboardCard

                            Icon={card.icon}
                            name={card.name}

                            navigateTo={card.navigateTo}
                            color={card.color}
                            desc={card.desc}
                        />
                    </Grid>
                ))
            }
        </Grid >
    );
}

export default DashboardCard
