import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconType } from "../Types/IconType";

export type CustomerSubmenuType = {
  name: string;
  icon: IconType;
};

export const CustomerSupportItems: CustomerSubmenuType[] = [
  { name: "Subscription plans", icon: SubscriptionsIcon },
  { name: "Contact Us", icon: PermPhoneMsgIcon },
  { name: "Logout", icon: LogoutIcon },
];
