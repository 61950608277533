import React from "react";

const PartyDues: React.FC = () => {
  return (
    <div>
      <h1>PartyDues</h1>
    </div>
  );
};

export default PartyDues;
