import React from "react";

const SubscriptionPlans: React.FC = () => {
  return (
    <div>
      <h1>SubscriptionPlans</h1>
    </div>
  );
};

export default SubscriptionPlans;
