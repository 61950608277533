export const Color = {
  DARK_BLUE: "#0c3c60",
  CREAM: "#E3E2DF",
  FRENCH_GREY: "#D1D7E0",
  GREY: "#AAABB8",
  PINK: "#C37292",
  DARK_PINK: "#AA4465",
  BLUE: "#81AAC9",
  MEDIUM_BLUE: "#447193",
};
