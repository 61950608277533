import * as React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,

  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Collapse,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const defaultTheme = createTheme();

const PasswordPage: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [username, setUserName] = React.useState("");

  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptTerms(event.target.checked);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setAlertOpen(true);
    } else {
      // Handle form submission
      setAlertOpen(false);
      console.log("Passwords match, form submitted.");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            {/* Add any icon or logo here */}
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Collapse in={alertOpen}>
              <Alert severity="error" sx={{ mb: 2 }}>
                Passwords do not match!
              </Alert>
            </Collapse>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="username"
                  label="Username"

                  id="username"
                  autoComplete="user-name"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}

                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Typography

                variant="body2"
                color="primary"
                onClick={handleOpenDialog}
                sx={{ cursor: "pointer", marginLeft: 3, marginTop: 1 }}
              >
                View Terms and Conditions
              </Typography>
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
              >
                <DialogTitle id="dialog-title">Terms and Conditions</DialogTitle>
                <DialogContent>
                  <DialogContentText sx={{ fontFamily: "Oswald" }} id="dialog-description">
                    {/* Basic terms and conditions */}
                    By accessing or using our inventory management system of Superb Diamonds,
                    you agree to be bound by these terms and conditions. If you do not agree
                    to these terms, you may not use the Product.
                  </DialogContentText>
                  <DialogContentText id="dialog-description" sx={{ fontFamily: "Oswald" }}>


                    You are responsible for ensuring the accuracy and completeness of all
                    data entered into the Product. We provide the Product on an "as-is" and
                    "as-available" basis, and we make no warranties or guarantees regarding
                    its performance, reliability, or suitability for your specific needs.
                  </DialogContentText>

                  <DialogContentText id="dialog-description" sx={{ fontFamily: "Oswald" }}>
                    {/* Specific terms related to inventory management */}
                    All data entered into the Product is your sole responsibility. We do
                    not monitor or verify the data you enter, and we are not liable for
                    any inaccuracies, errors, or omissions in your data. You are responsible
                    for regularly backing up your data to prevent loss.
                  </DialogContentText>

                  <DialogContentText id="dialog-description" sx={{ fontFamily: "Oswald" }}>
                    We implement standard security measures to protect your data; however,
                    we cannot guarantee absolute security. In the event of a data breach,
                    loss, or unauthorized access due to unforeseen circumstances, third-party
                    actions, or any other undue reasons, we will not be held liable for any
                    resulting damages or losses. You acknowledge and accept the risk
                    associated with storing your data in the Product.

                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acceptTerms}
                      onChange={handleTermsChange}
                      color="primary"
                    />
                  }
                  label="I accept all terms and conditions"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!acceptTerms}
            >
              Try free 14 day trial
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
              href="/plans" // Link to the plans page
            >
              View plans
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PasswordPage;
