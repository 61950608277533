import axios from "axios";
import { LoginRequestType } from "./types/LoginRequestType";

export const login = async (prop: LoginRequestType): Promise<any> => {
  try {
    const res = await axios.post("/auth/login", {
      login_variable: prop?.login_variable,
      password: prop?.password,
    });
    return res.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const errorMessage =
          error?.response?.data?.message || "Sorry! server is under maintanence";
        throw new Error(errorMessage);
      }
    } else {
      throw new Error("Unknown Error");
    }
  }
};
