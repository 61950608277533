import { Accordion, AccordionSummary, AccordionDetails, FormControl, Grid, TextField, FormLabel, Typography, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useContext } from 'react';
import { PurchaseContext } from '../Context/purchaseProviderContext';
import AddIcon from '@mui/icons-material/Add';

export default function LabInformation() {
    const { filterFormState, filterFormDispatch } = useContext(PurchaseContext);

    const [filterFormData, setFilterFormData] = useState<any>(filterFormState);


    useEffect(() => {
        setFilterFormData(filterFormState);
    }, [filterFormState]);

    const handleMeasurements = () => {
        filterFormDispatch({ type: 'SET_FILTER_FORM', payload: filterFormData });

    }
    const handleReset = () => {

        setFilterFormData({})
    };

    const handleInputChange = (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFilterFormData((filterFormData: any) => ({
            ...filterFormData,
            [fieldName]: value


        }));
    }

    // console.log("hello", filterFormState)
    return (
        <Grid item xs={12}>
            <Accordion sx={{ backgroundColor: '#d9e3ef', borderRadius: 2 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="lab-information-content"
                    id="lab-information-header"
                >
                    <Typography variant="h6" gutterBottom>Measurements</Typography>                </AccordionSummary>
                <AccordionDetails>
                    <FormControl fullWidth>
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Length"
                                    type="number"
                                    onChange={handleInputChange('length')}


                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Width"
                                    type="number"
                                    onChange={handleInputChange('width')}

                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Height"
                                    type="number"
                                    onChange={handleInputChange('height')}

                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Diameter"
                                    type="number"
                                    onChange={handleInputChange('diameter')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Depth"
                                    type="number"
                                    onChange={handleInputChange('depth')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Table"
                                    type="number"
                                    onChange={handleInputChange('table_size')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Girdle"
                                    type="number"
                                    onChange={handleInputChange('girdle')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Crown Angle"
                                    type="number"
                                    onChange={handleInputChange('cr_angle')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Crown Height"
                                    type="number"
                                    onChange={handleInputChange('cr_height')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Pavilion Angle"
                                    type="number"
                                    onChange={handleInputChange('p_angle')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    label="Pavilion Depth"
                                    type="number"
                                    onChange={handleInputChange('p_depth')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Comment"
                                    type="text"
                                    onChange={handleInputChange('comment')}
                                />
                            </Grid>

                        </Grid>
                        <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
                            <Box display="flex" gap={2}>

                                <Button variant="contained" color="primary" onClick={handleMeasurements}>
                                    <AddIcon sx={{ marginRight: 1 }} />
                                    Add measurements
                                </Button>
                                <Button variant="outlined" color="primary" onClick={handleReset}>
                                    Reset
                                </Button>

                            </Box>

                        </Box>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

