import React from "react";

const DiamondHistory: React.FC = () => {
  return (
    <div>
      <h1>DiamondHistory</h1>
    </div>
  );
};

export default DiamondHistory;
