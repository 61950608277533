import React from "react";

const AddParty: React.FC = () => {
  return (
    <div>
      <h1>AddParty</h1>
    </div>
  );
};

export default AddParty;