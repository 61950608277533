import React from 'react';
import { Box, Typography, Grid, TextField, Button, Container, Link } from '@mui/material';

interface Partner {
  name: string;
  email: string;
  phone: string;
}

const partners: Partner[] = [
  {
    name: 'Mehul Mehta',
    email: 'Mehul@example.com',
    phone: '+1 (555) 555-5555',
  },
  {
    name: 'Saurabh Mehta',
    email: 'saurabhkm1@yahoo.com',
    phone: '+919825351170',
  },
  {
    name: 'Ankit Mehta',
    email: 'ankit@example.com',
    phone: '+1 (555) 555-5557',
  },
];

const ContactUs: React.FC = () => {
  return (
    <>
      <Box
        my={4}
        sx={{
          // backgroundImage: 'linear-gradient(135deg, #606060 0%, #1c2833 100%)',
          // color: 'black',
          padding: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Contact us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Not sure what you need? You can contact us from the following details or suggest an idea.
        </Typography>

        <Grid container spacing={2} my={2}>
          {partners.map((partner, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box bgcolor="background.paper" p={2} borderRadius={2} sx={{
                backgroundImage: 'linear-gradient(135deg, #606060 0%, #1c2833 100%)',
              }}>
                <Typography variant="h6" gutterBottom color={'white'}>
                  {partner.name}
                </Typography>
                <Link href={`mailto:${partner.email}`} color="inherit">
                  <Typography variant="body2" gutterBottom color={'white'}>
                    Email: {partner.email}
                  </Typography>
                </Link>
                <Link href={`tel:${partner.phone}`} color="inherit">
                  <Typography variant="body2" color={'white'}>
                    Phone: {partner.phone}
                  </Typography>
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h6" gutterBottom>
          Ask a Question or Suggest an Idea
        </Typography>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Your Name"
                variant="outlined"
                fullWidth
                required
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{ style: { color: 'black' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Your Email"
                variant="outlined"

                type="email"
                fullWidth
                required
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{ style: { color: 'black' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Your Question or Suggestion"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                required
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{ style: { color: 'black' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default ContactUs;