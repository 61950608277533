import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Color } from '../Theme/Color';

interface TitleProps {
    children?: React.ReactNode;
}

export default function Title(props: TitleProps) {
    return (
        <Typography
            component="h2"
            variant="h5"
            color={Color.DARK_BLUE}
            sx={{ fontFamily: 'Raleway, sans-serif' }}
        >
            {props.children}
        </Typography>
    );
}
