import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const LogoTypography = styled(Typography)(({ theme }) => ({
    fontFamily: "Cormorant Infant",
    fontWeight: 700,
    fontSize: '3rem',
    color: '#1c2e4a',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '3rem',
    },
}));

const Logo: React.FC = () => {
    return (
        <LogoTypography>
            Superb Diamonds
        </LogoTypography>
    );
};

export default Logo;
