import React from "react";

const AccountEntries: React.FC = () => {
  return (
    <div>
      <h1>AccountEntries</h1>
    </div>
  );
};

export default AccountEntries;