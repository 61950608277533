export const buttonSizes = [
  { label: "0.30 - 0.39", size: 1.4 },
  { label: "0.40 - 0.49", size: 1.4 },
  { label: "0.50 - 0.69", size: 1.4 },
  { label: "0.70 - 0.89", size: 1.4 },
  { label: "0.90 - 0.99", size: 1.4 },
  { label: "1.00 - 1.49", size: 1.4 },
  { label: "1.50 - 1.99", size: 1.4 },
  { label: "2.00 - 2.99", size: 1.4 },
  { label: "3.00 - 3.99", size: 1.4 },
  { label: "4.00 - 4.99", size: 1.4 },
  { label: "5.00 - 5.99", size: 1.4 },
  { label: "6.00 - 9.99", size: 1.4 },
  { label: "10.00 - 10.99", size: 2 },
];
export const tableSpec = [
  "deal_date",
  "last_stock_id",
  "shape",
  "size_start",
  "size_end",
  "color",
  "clarity",
  "price",
  "location",
  "weight",
  "intensity",
  "overtone",
  "fancy_color",
  "cut_type",
  "cut_from",
  "cut_to",
  "polish_from",
  "polish_to",
  "symmetry_from",
  "symmetry_to",
  "fluorescence",
  "color_fluorescence",
  "kapan_number",
  "lab_reports",
  "length",
  "width",
  "height",
  "diameter",
  "depth",
  "table_size",
  "girdle",
  "age",
  "cr_angle",
  "cr_height",
  "p_angle",
  "p_depth",
  "comment",
  "partner_name",
  "partner_percentage",
  "invoice_number",

  "party_name",
  "brokerage",
  "broker",
  "party",
  "certificate_number",
  "cur_rap",
  "invoice_cmnt",
  "purchase_back",
  "purchase_rate",
];

export const LabReports = [
  "GIA",
  "GIA DOR",
  "AGS",
  "CGL",
  "DBIOD",
  "GCAL",
  "GHI",
  "GSI",
  "HRD",
  "IGI",
  "NGTC",
  "PGS",
  "RAP",
  "RDC",
  "SGI",
  "NONE",
];
export const diamondTradingCountries = [
  "United States",
  "India",
  "Belgium",
  "United Arab Emirates",
  "Israel",
  "Hong Kong",
  "Russia",
  "United Kingdom",
  "Canada",
  "South Africa",
  "Botswana",
  "Namibia",
  "Australia",
  "Thailand",
  "China",
  "Japan",
  "Switzerland",
  "Singapore",
  "Turkey",
  "Germany",
];
export const Intensity = [
  "Faint",
  "Very Light",
  "Light",
  "Fancy Light",
  "Fancy",
  "Fancy Intense",
  "Fancy Vivid",
  "Fancy Deep",
  "Fancy Dark",
];
export const shapes = [
  "Round",
  "Pear",
  "Oval",
  "Marquise",
  "Heart",
  "Radiant",
  "Princess",
  "Sq.Emerald",
  "Emerald",
  "Asscher",
  "SquareRadiant",
  "Cushion(All)",
  "CushionBrilliant",
  "Baguette",
  "EuropeanCut",
  "Star",
  "OldMiner",
  "Briolette",
  "Bullets",
  "Calf",
  "Circular",
  "Epaulette",
  "CushionModified",
  "HalfMoon",
  "Hexagonal",
  "Kite",
  "Lozenge",
  "Octagonal",
  "Pentagonal",
  "Rose",
  "Shield",
  "Square",
  "Trapezoid",
  "Triangular",
  "Trilliant",
];
export const colors = [
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export const clarities = [
  "FL",
  "IF",
  "VVS1",
  "VVS2",
  "VS1",
  "VS2",
  "SI1",
  "SI2",
  "SI3",
  "I1",
  "I2",
  "I3",
];
export const finishes = ["3X", "EX-", "VG+", "VG-"];
export const fancycolor = [
  "BLACK",
  "BLUE",
  "BROWN",
  "CHAMPAGNE",
  "COGNAC",
  "GREY",
  "GREEN",
  "ORANGE",
  "PINK",
  "PURPLE",
  "RED",
  "VIOLET",
  "YELLOW",
  "WHITE",
  "OTHER",
  "FAINT BLACK",
  "FAINT BLUE",
  "FAINT BROWN",
  "FAINT CHAMPAGNE",
  "FAINT COGNAC",
  "FAINT GREY",
  "FAINT GREEN",
  "FAINT ORANGE",
  "FAINT PINK",
  "FAINT PURPLE",
  "FAINT RED",
  "FAINT VIOLET",
  "FAINT YELLOW",
  "FAINT WHITE",
  "FAINT OTHER",
  "VERY LIGHT BLACK",
  "VERY LIGHT BLUE",
  "VERY LIGHT BROWN",
  "VERY LIGHT CHAMPAGNE",
  "VERY LIGHT COGNAC",
  "VERY LIGHT GREY",
  "VERY LIGHT GREEN ",
  "VERY LIGHT PINK",
  "VERY LIGHT PURPLE",
  "VERY LIGHT RED",
  "VERY LIGHT VIOLET",
  "VERY LIGHT YELLOW",
  "VERY LIGHT WHITE",
  "VERY LIGHT OTHER",
  "LIGHT BLACK",
  "LIGHT BLUE",
  "LIGHT BROWN",
  "LIGHT CHAMPAGNE",
  "LIGHT COGNAC",
  "LIGHT GREY",
  "LIGHT GREEN",
  "LIGHT ORANGE",
  "LIGHT PINK",
  "LIGHT PURPLE",
  "LIGHT RED",
  "LIGHT VIOLET",
  "LIGHT YELLOW",
  "LIGHT WHITE",
  "LIGHT OTHER",
  "FANCY LIGHT BLACK",
  "FANCY LIGHT BLUE",
  "FANCY LIGHT BROWN",
  "FANCY LIGHT CHAMPAGN",
  "FANCY LIGHT COGNAC",
  "TA FANCY LIGHT GREY",
  "FANCY LIGHT GREEN",
  "FANCY LIGHT ORANGE",
  "O FANCY LIGHT PINK",
  "FANCY LIGHT PURPLE",
  "FANCY LIGHT RED",
  "FANCY LIGHT VIOLET",
  "FANCY LIGHT YELLOW",
  "FANCY LIGHT WHITE",
  "FANCY LIGHT",
  "OTHER FANCY BLUE",
  "FANCY BROWN",
  "FANCY CHAMPAGNE",
  "FANCY COGNAC",
  "FANCY GREY",
  "FANCY GREEN",
  "FANCY ORANGE",
  "FANCY PINK",
  "FANCY PURPLE",
  "FANCY RED",
  "FANCY VIOLET",
  "FANCY YELLOW",
  "FANCY WHITE",
  "FANCY OTHER",
  "FANCY DARK BLACK",
  "FANCY DARK BLUE",
  "FANCY DARK BROWN",
  "FANCY DARK CHAMPAGNE",
  "FANCY DARK COGNAC",
  "FANCY DARK GREY",
  "FANCY DARK GREEN",
  "FANCY DARK ORANGE",
  "FANCY DARK PINK",
  "FANCY DARK PURPLE",
  "FANCY DARK RED",
  "FANCY DARK VIOLET",
  "FANCY DARK YELLOW",
  "FANCY DARK WHITE",
  "FANCY DARK OTHER",
  "FANCY INTENSE BLACK",
  "FANCY INTENSE BLUE",
  "FANCY INTENSE BROWN",
  "FANCY INTENSE CHAMPA",
  "FANCY INTENSE COGNAC",
  "FANCY INTENSE GREY",
  "FANCY INTENSE GREE",
  "FANCY INTENSE ORANGE",
  "FANCY INTENSE PINK",
  "FANCY INTENSE PURPLE ",
  "FANCY INTENSE RED",
  "FANCY INTENSE VIOLET",
  "FANCY INTENSE YELLOW",
  "FANCY INTENSE WHITE",
  "FANCY INTENSE OTHER",
  "FANCY VIVID BLACK",
  "FANCY VIVID BLUE",
  "FANCY VIVID BROWN",
  "FANCY VIVID CHAMPAGN",
  "FANCY VIVID COGNAC",
  "FANCY VIVID GREY",
  "FANCY VIVID GREEN",
  "FANCY VIVID ORANGE",
  "FANCY VIVID PINK",
  "FANCY VIVID PURPLE",
  "FANCY VIVID RED",
  "FANCY VIVID VIOLET",
  "FANCY VIVID YELLOW",
  "FANCY VIVID WHITE",
  "FANCY VIVID",
  "OTHER FANCY DEEP BLACK",
  "FANCY DEEP BLUE",
  "FANCY DEEP BROWN",
  "FANCY DEEP CHAMPAGNE",
  "FANCY DEEP COGNAC",
  "FANCY DEEP GREY",
  "FANCY DEEP GREEN",
  "FANCY DEEP ORANGE",
  "FANCY DEEP PINK",
  "FANCY DEEP PURPLE",
  "FANCY DEEP RED",
  "FANCY DEEP VIOLET",
  "FANCY DEEP YELLOW",
  "FANCY DEEP WHITE",
  "FANCY DEEP OTHER",
  "VERY LIGHT BROWN",
  "EXCEPTIONAL WHITE (E)",
  "RARE WHITE +(F)",
  "TINTED WHITE+(K)",
  "SLIGHTLY TINTED WHITE+ (1)",
  "WHITE (H)",
  "SLIGHTLY TINTED WHITE (1)",
  "SLIGHTLY TINTED WHITE (1) ECG",
  "SLIGHTLY TINTED WHITE +(1) ECG TINTED WHITE(K)",
  "SLIGHTLY TINTED WHITE (J)",
  "SLIGHTLY TINTED WHITE+ (1) (1)EQUIV. COL. GRADING",
  "SLIGHTLY TINTED WHITE (J)EQUIV COL. GRADING",
  "TINTED COLOUR (N-O)",
  "WHITE (H)EQUIV. COL. GRADING",
  "RARE WHITE (G)",
  "EXCEPTIONAL WHITE (D)",
];
export const Quality = [
  "Ideal",
  "Excellent",
  "Very Good",
  "Good",
  "Fair",
  "Poor",
];
export const Fluorescence = [
  "None",
  "Very Slight",
  "Faint / Slight",
  "Medium",
  "Strong",
  "Very Strong",
];
