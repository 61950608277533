import { createSlice } from "@reduxjs/toolkit";

export type AuthState = {
    isLoggedIn: boolean;
    isError: boolean
};

const initialState: AuthState = {
    isLoggedIn: false,
    isError: false
};

export const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        loggedIn: (state) => {
            state.isLoggedIn = true;
        },
        loggedOut: (state) => {
            state.isLoggedIn = false;
            state.isError = false;
            console.log('logging out');
            document.cookie = 'JWT_token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';;
        },
        setAuthError: (state, action) => {
            state.isError = action.payload;
        }
    },
});

export const { loggedIn, loggedOut, setAuthError } = authSlice.actions;

export default authSlice;
