import React from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

interface AddNewPartyModalProps {
    open: boolean;
    onClose: () => void;
}

const AddNewPartyModal: React.FC<AddNewPartyModalProps> = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 5 }}>
                <Typography variant="h6" gutterBottom>Add New Party</Typography>
                <TextField label="Party Name" fullWidth margin="normal" />
                <TextField label="Phone Number" fullWidth margin="normal" />
                <TextField label="Address" fullWidth margin="normal" />
                <TextField label="Email" fullWidth margin="normal" />
                <TextField label="Opening Balance" fullWidth margin="normal" />
                <Button variant="contained" color="primary" fullWidth onClick={onClose}>Submit</Button>
            </Box>
        </Modal>
    );
};

export default AddNewPartyModal;
