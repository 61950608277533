import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Modules/Dashboard/Pages/Home";
import ContactUs from "./Pages/SideNavPages/ContactUs";
import AccountSettings from "./Pages/SideNavPages/AccountSettings";
import Feedback from "./Pages/SideNavPages/Feedback";
import DownloadReport from "./Pages/SideNavPages/DownloadReport";
import Rapnet from "./Pages/SideNavPages/Rapnet";
import DiamondHistory from "./Pages/SideNavPages/DiamondHistory";
import AddParty from "./Pages/SideNavPages/AddParty";
import RepairUpload from "./Pages/SideNavPages/RepairUpload";
import PartyDues from "./Pages/SideNavPages/PartyDues";
import ViewPendingPackets from "./Pages/SideNavPages/ViewPendingPackets";
import PacketPrinting from "./Pages/SideNavPages/PacketPrinting";
import BalanceSheet from "./Pages/SideNavPages/BalanceSheet";
import AccountEntries from "./Pages/SideNavPages/AccountEntries";
import SubscriptionPlans from "./Pages/SideNavPages/SubscriptionPlans";
import Login from "./Modules/Auth/Pages/Login";
import SignUp from "./Modules/Auth/Pages/Signup";
import PasswordPage from "./Modules/Auth/Pages/Password";
import PlansPage from "./Modules/Auth/Pages/Plans";
import Account from './Pages/DashboardCards/FileUploader';
import NonCertifiedDiamond from './Pages/DashboardCards/NonCertifiedDiamond';
import Payment from './Pages/DashboardCards/Payment';
import Report from './Pages/DashboardCards/Report';
import Sell from './Modules/Sell/Pages/Sell';
import Stock from './Pages/DashboardCards/Stock';
import Purchase from './Modules/Purchase/Pages/Purchase';
import CertifiedDiamonds from './Pages/DashboardCards/CertifiedDiamonds';
import Profile from "./Pages/Profile/Profile";
import Root from './Pages/Root';
import { store } from './store/store';
import { Provider } from 'react-redux';
import './index.css'
import EmailVerification from './Modules/Auth/Pages/EmailVerification';
import { CheckPermission } from './Component/CheckPermission';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />

          <Route element={<Root />} >
            <Route path='/home' element={<CheckPermission> <Home /> </CheckPermission>} />
            <Route path="/password" element={<CheckPermission> <PasswordPage /> </CheckPermission>} />
            <Route path="/plans" element={<CheckPermission> <PlansPage /> </CheckPermission>} />
            <Route path="/account-entries" element={<CheckPermission> <AccountEntries /> </CheckPermission>} />
            <Route path="/balance-sheet" element={<CheckPermission> <BalanceSheet /> </CheckPermission>} />
            <Route path="/packet-printing" element={<CheckPermission><PacketPrinting /></CheckPermission>} />
            <Route path="/view-pending-packets" element={<CheckPermission><ViewPendingPackets /></CheckPermission>} />
            <Route path="/party-dues" element={<CheckPermission><PartyDues /></CheckPermission>} />
            <Route path="/repair-upload" element={<CheckPermission><RepairUpload /></CheckPermission>} />
            <Route path="/add-party" element={<CheckPermission><AddParty /></CheckPermission>} />
            <Route path="/diamond-history" element={<CheckPermission><DiamondHistory /></CheckPermission>} />
            <Route path="/rapnet" element={<CheckPermission><Rapnet /></CheckPermission>} />
            <Route path="/contact-us" element={<CheckPermission><ContactUs /></CheckPermission>} />
            <Route path="/accountsettings" element={<CheckPermission><AccountSettings /></CheckPermission>} />
            <Route path="/feedback" element={<CheckPermission><Feedback /></CheckPermission>} />
            <Route path="/downloadreport" element={<CheckPermission><DownloadReport /></CheckPermission>} />
            <Route path="/subscription-plans" element={<CheckPermission><SubscriptionPlans /></CheckPermission>} />
            <Route path="/account" element={<CheckPermission><Account /></CheckPermission>} />
            <Route path="/certified-diamonds" element={<CheckPermission><CertifiedDiamonds /></CheckPermission>} />
            <Route path="/non-certified-diamonds" element={<CheckPermission><NonCertifiedDiamond /></CheckPermission>} />
            <Route path="/payment" element={<CheckPermission><Payment /></CheckPermission>} />
            <Route path="/report" element={<CheckPermission><Report /></CheckPermission>} />
            <Route path="/sell" element={<CheckPermission><Sell /></CheckPermission>} />
            <Route path="/stock" element={<CheckPermission><Stock /></CheckPermission>} />
            <Route path="/purchase" element={<CheckPermission><Purchase /></CheckPermission>} />
            <Route path="/profile" element={<CheckPermission><Profile /></CheckPermission>} />
            <Route path="/verify" element={<CheckPermission><EmailVerification /></CheckPermission>} />
          </ Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
