import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ListItemProps } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon } from '@mui/material';
import { IconType } from '../Types/IconType';

interface ListItemLinkProps extends ListItemProps {
    to: string;
    open?: boolean;
}

type CustomListItemLinkProps = ListItemLinkProps & {
    map: { [key: string]: string },
    iconMap?: { [key: string]: IconType },
    parentName?: string,
}

function CustomListItemLink(props: CustomListItemLinkProps) {
    const { to, open, map, iconMap, parentName, ...other } = props;
    const primary = parentName ? parentName : map[to];
    const IconComponent = iconMap ? iconMap[to] : null

    let iconUpDown = null;
    if (open != null) {
        iconUpDown = open ? <ExpandLess /> : <ExpandMore />;
    }

    return (
        <li>
            <ListItemButton component={RouterLink as any} to={to} {...other}>
                {IconComponent && <ListItemIcon>
                    <IconComponent />
                </ListItemIcon>
                }
                <ListItemText primary={primary} />
                {iconUpDown}
            </ListItemButton>
        </li>
    );
}

type CustomRouterBreadcrumbProps = {
    parentName: string,
    linkTextMap: { [key: string]: string }
    iconMap: { [key: string]: IconType }
}

export default function CustomRouterBreadcrumb({ parentName, linkTextMap, iconMap }: CustomRouterBreadcrumbProps) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (

        <Box sx={{ display: 'flex', flexDirection: 'column', width: 'inherit' }}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    mt: 1,
                    ml: 1,
                }}
                component="nav"
                aria-label="mailbox folders"
            >
                <List>
                    <CustomListItemLink to="" open={open} onClick={handleClick} map={linkTextMap} parentName={parentName} />
                    <Collapse component="li" in={open} timeout="auto" unmountOnExit>
                        {Object.keys(linkTextMap).map((key, index) => (
                            <List disablePadding key={key}>
                                <CustomListItemLink sx={{ pl: 4 }} to={key} iconMap={iconMap} map={linkTextMap} />
                            </List>
                        ))}
                    </Collapse>

                </List>
            </Box>
        </Box>
    );
}