import * as React from 'react';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import Title from './Title';
interface DataGridProps<T> {
    columns: GridColDef[];
    rows: T[];
    title: string;
    pageSizeOptions?: number[];
    initialPageSize?: number;
}

export const DiamondDataGrid = <T,>({ columns, rows, title, pageSizeOptions = [5, 10], initialPageSize = 5 }: DataGridProps<T>) => {
    return (
        <Box sx={{ height: 400, width: '100%', display: "table", tableLayout: "fixed", overflowX: 'auto' }}>
            <Title >{title}</Title>
            <DataGrid

                autoHeight
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: initialPageSize },
                    },
                }}
                pageSizeOptions={pageSizeOptions}
                checkboxSelection
            />
        </Box>

    );
};




