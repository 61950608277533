import React, { useState } from "react";
import axios from "axios";
import CustomSnackBar, { snackbarType } from "../../../Component/CustomSnackBar";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Paper,
    Typography,
} from "@mui/material";
import SellInvoice from "../Component/SellInvoice";
import StockDataCard from "../../../Component/StockDataCard";
// import { API_BASE_URL } from "../../../backend";

const Sell: React.FC = () => {
    const [onSuccess, setOnSuccess] = useState<snackbarType>({
        success: false,
        message: "",
    });
    const [stockId, setStockId] = useState("");
    const [kapanId, setKapanId] = useState("");
    const [data, setData] = useState<any>({});
    const [showData, setShowData] = useState(false);

    const handleSearch = async () => {
        if (!stockId) {
            setOnSuccess({ success: true, message: "Please provide stone ID!", variant: 'error' });
            return;
        }

        try {
            const response = await axios.post("/stock/view-stock", {
                last_stock_id: stockId,
            });
            const data = response.data[0];

            setData(data);
            console.log("check", data)
            if (data === undefined) {
                setOnSuccess({ success: true, message: `There is no stock of ${stockId}`, variant: 'warning' });

            }
            else {

                setOnSuccess({ success: true, message: "Data fetched successfully!" });
            }
            setShowData(true);

        } catch (error) {
            setOnSuccess({ success: true, message: "Error fetching data!", variant: 'error' });
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            <FormControl fullWidth sx={{ backgroundColor: "#d9e3ef", padding: 2, borderRadius: 2 }}>
                <FormLabel sx={{ mb: 2 }}>Stock Information</FormLabel>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white", borderRadius: 2 }}
                            fullWidth
                            label="Stock ID (STCK-XXXX)"
                            type="text"
                            value={stockId}
                            onChange={(e) => setStockId(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white", borderRadius: 2 }}
                            fullWidth
                            label="Certificate ID"
                            type="text"
                        // value={kapanId}
                        // onChange={(e) => setKapanId(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </FormControl>
            {showData && (
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Fetched Data:
                    </Typography>
                    <StockDataCard stockData={data} />

                    {/* <StockDetails data={data} /> */}
                </Paper>
            )}


            <SellInvoice data={{ price: data?.price, weight: data?.size_start }} />

        </Box>
    );
};

export default Sell;
