import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';
import {
    Box,
    Typography,
    Paper,
    ThemeProvider,
    createTheme,
    CssBaseline,
    TextField,
    Button
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate, useLocation } from 'react-router';
// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        background: {
            default: '#f5f5f5',
        },
    },
});


const EmailVerification: React.FC = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { email, correctotp } = location.state;


    console.log("RAMOTP", correctotp)


    const handleOtpChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/\D/g, '');
        if (value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to next input if value is entered
            if (value.length === 1 && index < 5) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            // Move to previous input on backspace if current input is empty
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleVerify = () => {
        const otpString = otp.join('');
        console.log('Verifying OTP:', otpString);
        console.log("correct", correctotp)
        if (+otpString === correctotp) {
            console.log('OTP is correct, navigating to password screen');
            navigate('/password', { state: { email } }); // Adjust the route as needed
        } else {
            console.error('Incorrect OTP, please try again');
            // You can also set an error state here to show an error message
        }
        // Add your OTP verification logic here
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 10,
                    // justifyContent: 'center',
                    minHeight: '100vh',

                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 4,
                        width: '100%',
                        maxWidth: 400,
                    }}
                >
                    <EmailIcon
                        style={{ fontSize: 64, color: '#1976d2', marginBottom: '16px' }}
                    />
                    <Typography variant="h5" component="h1" gutterBottom>
                        Email Verification
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        We have sent a 6-digit OTP to your registered email:
                        {email && (
                            <>
                                {/* <br /> */}
                                <Box component="span" fontWeight="bold">
                                    {email}
                                </Box>
                                {/* <br /> */}
                            </>
                        )}
                        . Please enter the OTP below to verify your account.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            my: 2,
                        }}
                    >
                        {otp.map((digit, index) => (
                            <TextField
                                key={index}
                                inputRef={(el) => (inputRefs.current[index] = el)}
                                value={digit}
                                onChange={handleOtpChange(index)}
                                onKeyDown={handleKeyDown(index)}
                                variant="outlined"
                                inputProps={{
                                    maxLength: 1,
                                    style: {
                                        textAlign: 'center',
                                        fontSize: '1.2rem',
                                        padding: '8px 0',
                                    },
                                }}
                                sx={{
                                    width: '14%',
                                    '& .MuiInputBase-root': {
                                        height: '40px',
                                    },
                                    '@media (max-width:400px)': {
                                        width: '13%',
                                        '& .MuiInputBase-root': {
                                            height: '35px',
                                        },
                                        '& .MuiInputBase-input': {
                                            fontSize: '1rem',
                                            padding: '4px 0',
                                        },
                                    },
                                }}
                            />
                        ))}
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleVerify}
                        disabled={otp.some(digit => digit === '')}
                        sx={{ mt: 2 }}
                    >
                        Verify OTP
                    </Button>
                </Paper>
            </Box>
        </ThemeProvider>
    );
};

export default EmailVerification;