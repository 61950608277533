import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userSlice from "./slice/userSlice";
import authSlice from "./slice/authSlice";

const userPersistConfig = {
  key: "user",
  storage,
};

const authPersistConfig = {
  key: "auth",
  storage,
};

const persistedUserReducer = persistReducer(
  userPersistConfig,
  userSlice.reducer
);
const persistedAuthReducer = persistReducer(
  authPersistConfig,
  authSlice.reducer
);

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    auth: persistedAuthReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions with non-serializable values
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        // Ignore specific paths in the state that may hold non-serializable data
        ignoredPaths: ["register", "rehydrate"],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
