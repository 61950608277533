import axios from "axios";

export const fetchUser = async (loginVariable: string): Promise<any> => {
  console.log("this is login var", loginVariable);
  try {
    const res = await axios.get(`/auth/user-details/${loginVariable}`);

    return res?.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // Log detailed information about the error
        console.error("Error Response Data:", error.response.data);
        console.error("Error Response Status:", error.response.status);
        console.error("Error Response Headers:", error.response.headers);
        const errorMessage = error.response.data.message;
        throw new Error(errorMessage);
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error Request:", error.request);
        throw new Error("No response received from server");
      } else {
        // Something else happened
        console.error("Error Message:", error.message);
        throw new Error("An error occurred");
      }
    } else {
      console.error("Unknown Error:", error);
      throw new Error("Unknown Error");
    }
  }
};
