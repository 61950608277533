import React, { createContext, useReducer, Dispatch } from "react";

type PurchaseType = {
  filterFormState: any;
};

// type FilterFormType = {

// };

const initialFilterForm = {}

const initialState: PurchaseType = {
  filterFormState: initialFilterForm,
};

type Action = { type: "SET_FILTER_FORM"; payload: any };

const PurchaseReducer = (state: PurchaseType, action: Action): PurchaseType => {
  switch (action.type) {
    case "SET_FILTER_FORM":
      return {
        ...state,
        filterFormState: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

interface PurchaseContextType {
  filterFormState: any;
  filterFormDispatch: Dispatch<Action>;
}

export const PurchaseContext = createContext<PurchaseContextType>({
  filterFormState: initialFilterForm,
  filterFormDispatch: () => { },
});

export interface PurchaseContextProviderProps {
  children: React.ReactNode;
}

export const PurchaseContextProvider = ({
  children,
}: PurchaseContextProviderProps) => {
  const [state, dispatch] = useReducer(PurchaseReducer, initialState);

  return (
    <PurchaseContext.Provider
      value={{ filterFormState: state.filterFormState, filterFormDispatch: dispatch }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};
