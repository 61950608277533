import * as React from "react";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Box,
  Button,
  CssBaseline,
} from "@mui/material";
// import StarIcon from '@mui/icons-material/StarBorder';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const defaultTheme = createTheme();

const tiers = [
  {
    title: '3 Month',
    price: '7,999',
    description: ['Add partners', 'Contacts via Rapnet', 'Help center access', 'Email support'],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
  },
  {
    title: '6 Month',
    subheader: 'Most popular',
    price: '14,999',
    description: [
      '+ All features from basic plan',
      '5 View stock',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: '12 Month',
    price: '28,999',
    description: [
      '+premium plan',
      'Unlimited stock viewing of party',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
  },
];

const PlansPage: React.FC = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ marginTop: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography component="h1" variant="h4" gutterBottom>
            Choose Your Plan
          </Typography>
          <Grid container spacing={4} alignItems="flex-end">
            {tiers.map((tier) => (
              <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    // action={tier.title === 'Pro' ? <StarIcon /> : null}
                    sx={{ backgroundColor: (theme) => theme.palette.grey[200] }}
                  />
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 2 }}>
                      <Typography component="h2" variant="h3" color="textPrimary">
                        ₹{tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /yr
                      </Typography>
                    </Box>
                    {tier.description.map((line) => (
                      <Typography variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions>
                    <Button fullWidth color="primary">
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PlansPage;
